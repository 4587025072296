import type { GeoJSONPoint } from 'core';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { MarkerDragEvent } from 'react-map-gl';
import { Marker, useMap } from 'react-map-gl';
import { Pin } from '../markers/pin.js';

interface Props {
  point?: GeoJSONPoint | null;
  onPointChange: (point: GeoJSONPoint) => void;
}

export const DraggableMarkerLayer: FC<Props> = ({ point, onPointChange }) => {
  const [coordinates, setCoordinates] = useState(point?.coordinates);
  const [dragging, setDragging] = useState(false);
  const { current } = useMap();

  useEffect(() => {
    setCoordinates(point?.coordinates);
  }, [point]);

  useEffect(() => {
    if (!current) return;
    current.getCanvas().style.cursor = dragging ? 'move' : '';
  }, [current, dragging]);

  const onMarkerDragStart = useCallback(() => {
    setDragging(true);
  }, []);

  const onMarkerDrag = useCallback((event: MarkerDragEvent) => {
    setCoordinates([event.lngLat.lng, event.lngLat.lat]);
  }, []);

  const onMarkerDragEnd = useCallback(() => {
    setDragging(false);
    coordinates && onPointChange({ type: 'Point', coordinates });
  }, [coordinates, onPointChange]);

  if (!coordinates) return null;

  return (
    <Marker
      longitude={coordinates[0]}
      latitude={coordinates[1]}
      anchor="bottom"
      draggable
      onDragStart={onMarkerDragStart}
      onDrag={onMarkerDrag}
      onDragEnd={onMarkerDragEnd}
      style={{ cursor: 'move', height: dragging ? 36 : 24 }}
    >
      <Pin size={dragging ? 36 : 24} />
    </Marker>
  );
};
