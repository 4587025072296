import { colors } from '@mui/material';
import { memo } from 'react';

const ICON =
  'M20.2 15.7C21.3 14.1 22 12.1 22 10C22 4.4 17.5 0 12 0C6.5 0 2 4.5 2 10C2 12 2.6 13.9 3.6 15.4C3.74253 15.6138 3.9 15.9 3.9 15.9C3.9 15.9 4.26389 16.3993 4.6 16.8C7.2 19.9 12 24 12 24C12 24 16.8167 19.8 19.4167 16.8C19.4646 16.7448 19.8 16.3 20 16C20 16 20.1252 15.8088 20.2 15.7Z';

const pinStyle = {
  fill: colors.deepOrange[500],
  stroke: 'none',
};

export const Pin = memo((props: { size: number }) => {
  const { size = 20 } = props;

  return (
    <svg height={size} viewBox="0 0 24 24" style={pinStyle}>
      <title>Pin</title>
      <path d={ICON} />
    </svg>
  );
});
