import { Box } from '@mui/material';
import type { Property } from 'csstype';
import 'mapbox-gl/dist/mapbox-gl.css';
import type { FC, PropsWithChildren } from 'react';
import {
  FullscreenControl,
  Map as Mapbox,
  type LngLatBoundsLike,
  type PaddingOptions,
  type PointLike,
  type ViewState,
} from 'react-map-gl';

export const MAP_PADDING = 16;

interface FitBoundsOptions {
  offset?: PointLike;
  minZoom?: number;
  maxZoom?: number;
  padding?: number | PaddingOptions;
}

export interface InitialViewState extends Partial<ViewState> {
  bounds?: LngLatBoundsLike;
  fitBoundsOptions?: FitBoundsOptions;
}

interface Props {
  height?:
    | Property.Height<string | number>
    | NonNullable<Property.Height<string | number> | undefined>[]
    | undefined;
  initialViewState: InitialViewState;
  interactive?: boolean;
  mapStyle: string;
  maxHeight?:
    | Property.Height<string | number>
    | NonNullable<Property.Height<string | number> | undefined>[]
    | undefined;
  maxWidth?:
    | Property.Width<string | number>
    | NonNullable<Property.Width<string | number> | undefined>[]
    | undefined;
  minHeight?:
    | Property.Height<string | number>
    | NonNullable<Property.Height<string | number> | undefined>[]
    | undefined;
  minWidth?:
    | Property.Width<string | number>
    | NonNullable<Property.Width<string | number> | undefined>[]
    | undefined;
  width?:
    | Property.Width<string | number>
    | NonNullable<Property.Width<string | number> | undefined>[]
    | undefined;
}

export const MapView: FC<PropsWithChildren<Props>> = (props) =>
  (props.initialViewState.latitude && props.initialViewState.longitude) ||
  props.initialViewState.bounds ? (
    <MapWithBounds {...props} />
  ) : (
    <Box
      display="inline-grid"
      height={props.height ?? '100%'}
      width={props.height ?? '100%'}
    />
  );

export const MapWithBounds: FC<PropsWithChildren<Props>> = ({
  children,
  height = '100%',
  initialViewState,
  interactive = true,
  mapStyle,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  width = '100%',
}) => (
  <Box
    height={height}
    maxHeight={maxHeight}
    maxWidth={maxWidth}
    minHeight={minHeight}
    minWidth={minWidth}
    position="relative"
    width={width}
    sx={{
      '& .mapboxgl-ctrl-attrib': {
        opacity: 0.8,
        fontSize: '0.5rem',
      },
      '& .mapboxgl-ctrl-logo': {
        opacity: 0.6,
      },
    }}
  >
    <Mapbox
      initialViewState={{
        ...initialViewState,
      }}
      cooperativeGestures={true}
      interactive={interactive}
      mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN as string}
      mapStyle={mapStyle}
      minZoom={11.5}
    >
      <FullscreenControl />
      {children}
    </Mapbox>
  </Box>
);
