import type { FormControlProps } from '@mui/material';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { captureException } from '@sentry/browser';
import type { FormikProps } from 'formik';
import type { ReactNode } from 'react';

interface Props<ID, T, I> {
  id: ID;
  defaultValue: string;
  disabled?: boolean;
  formik: FormikProps<T>;
  items: I[];
  label: ReactNode;
  FormControlProps?: FormControlProps;
}

export function FormikRadioGroup<
  ID extends string,
  T extends { [key in ID]?: string | null | undefined },
  I extends { id: string; display: ReactNode },
>(props: Props<ID, T, I>): JSX.Element {
  const { defaultValue, formik, items } = props;
  const value = formik.values[props.id];
  return (
    <FormControl
      disabled={formik.isSubmitting || props.disabled}
      error={formik.touched[props.id] && Boolean(formik.errors[props.id])}
      {...props.FormControlProps}
    >
      <FormLabel
        id={`radio-group-label-${props.id}`}
        sx={{ fontSize: '0.75em' }}
      >
        {props.label}
      </FormLabel>
      <RadioGroup
        id={props.id}
        aria-labelledby={`radio-group-label-${props.id}`}
        name={`radio-group-${props.id}`}
        onChange={(e) => {
          formik.setFieldTouched(props.id, true);
          formik
            .setFieldValue(props.id, e.target.value)
            .catch(captureException);
        }}
        row
        value={value ?? defaultValue}
      >
        {items.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.id}
            control={<Radio />}
            label={item.display}
          />
        ))}
      </RadioGroup>
      <FormHelperText id={`${props.id}-helper-text`} sx={{ ml: 0, mt: -0.5 }}>
        {formik.errors[props.id]?.toString()}
      </FormHelperText>
    </FormControl>
  );
}
