import { helpers } from '@turf/turf';
import type { GeoJSONPoint } from './data-point.js';

export interface CourseControl {
  _id: string;
  lat?: number;
  long?: number;
  type?: 'start' | 'finish';
  radius?: number;
  altitude?: number;
}

export interface EventControl {
  _id: string;
  location: GeoJSONPoint;
  type: string;
  radius?: number;
  altitude?: number;
  points?: number;
}

export const sortEventControl = (a: EventControl, b: EventControl): number => {
  if (a.type !== b.type) {
    if (a.type === 'start') return -1;
    if (b.type === 'start') return 1;
    if (a.type === 'finish') return 1;
    if (b.type === 'finish') return -1;
  }
  return a._id.localeCompare(b._id);
};

export interface Control {
  lat: number;
  long: number;
  type?: 'start' | 'finish';
  radius?: number;
  altitude?: number;
  points?: number;
}

export type ResolvedControl = CourseControl | (Control & CourseControl);

export const toEventControl = (
  _id: string,
  control: Control,
): EventControl => ({
  _id,
  type: control.type ?? 'control',
  location: helpers.point([control.long, control.lat]).geometry,
  radius: control.radius,
  altitude: control.altitude,
  points: control.points,
});

export const fromEventControl = (control: EventControl): Control => ({
  type:
    control.type === 'start'
      ? 'start'
      : control.type === 'finish'
        ? 'finish'
        : undefined,
  lat: control.location.coordinates[1],
  long: control.location.coordinates[0],
  radius: control.radius,
  altitude: control.altitude,
  points: control.points,
});

export const toEventControls = (
  controls: Record<string, Control>,
): Record<string, EventControl> =>
  Object.keys(controls).reduce<Record<string, EventControl>>((acc, key) => {
    acc[key] = toEventControl(key, controls[key]);
    return acc;
  }, {});

export const fromEventControls = (
  controls: Record<string, EventControl>,
): Record<string, Control> =>
  Object.keys(controls).reduce<Record<string, Control>>((acc, key) => {
    acc[key] = fromEventControl(controls[key]);
    return acc;
  }, {});
