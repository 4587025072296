import type { GeoJSONPoint } from 'core';
import type { Property } from 'csstype';
import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { useMap, type LngLatLike } from 'react-map-gl';
import { withMap } from '../../../hoc/with-map.js';
import { magneticDeclination } from '../../../utils/geo.js';
import { MapView } from './view.js';

interface Props {
  center?: GeoJSONPoint | null;
  height?:
    | Property.Height<string | number>
    | NonNullable<Property.Height<string | number> | undefined>[]
    | undefined;
  initialZoom?: number;
  interactive?: boolean;
  mapStyle: string;
  minHeight?:
    | Property.Height<string | number>
    | NonNullable<Property.Height<string | number> | undefined>[]
    | undefined;
  minWidth?:
    | Property.Width<string | number>
    | NonNullable<Property.Width<string | number> | undefined>[]
    | undefined;
  width?:
    | Property.Width<string | number>
    | NonNullable<Property.Width<string | number> | undefined>[]
    | undefined;
}

export const CenterMiniMap: FC<PropsWithChildren<Props>> = withMap(
  ({ center, ...props }) => {
    const { default: map } = useMap();

    useEffect(() => {
      if (!map) return;

      let blockReEntry = false;
      const moved = (): void => {
        if (!map || blockReEntry) return;
        blockReEntry = true;
        const center = map.getCenter();
        map.setBearing(magneticDeclination(center.lng, center.lat));
        blockReEntry = false;
      };

      map.on('moveend', moved);

      return () => {
        map.off('moveend', moved);
      };
    }, [map]);

    useEffect(() => {
      if (!center) return;
      map?.flyTo({ center: center.coordinates as LngLatLike });
    }, [map, center]);

    const initialViewState = {
      bearing: center
        ? magneticDeclination(center.coordinates[0], center.coordinates[1])
        : 0,
      longitude: center?.coordinates[0],
      latitude: center?.coordinates[1],
      zoom: props.initialZoom ?? 17,
    };

    return MapView({
      ...props,
      initialViewState,
    });
  },
);
