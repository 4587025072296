import type { OutlinedInputProps, TextFieldProps } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { captureException } from '@sentry/browser';
import type { Feature, Point } from '@turf/turf';
import type { AxiosObservable } from 'axios-observable';
import Axios from 'axios-observable';
import type { GeoJSONPoint } from 'core';
import type { FormikErrors, FormikProps } from 'formik';
import type { FocusEventHandler, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { debounceTime, from, map, mergeMap } from 'rxjs';
import uri from 'uri-tag';

type Props<ID, T> = TextFieldProps &
  OutlinedInputProps & { id: ID; formik: FormikProps<T> };

interface AddressFeature extends Feature<Point> {
  place_name: string;
  place_type: string;
}

const formatPoint = (value?: GeoJSONPoint | null): string =>
  value
    ? `${value.coordinates[1].toFixed(7)}, ${value.coordinates[0].toFixed(7)}`
    : '';

const [textChange$, setText] = createSignal<string>();

const findAddresses = mergeMap(
  (text: string): AxiosObservable<{ features: AddressFeature[] }> => {
    if (!text) {
      return from([{ data: {} }]) as AxiosObservable<{
        features: AddressFeature[];
      }>;
    }

    return Axios.get<{ features: AddressFeature[] }>(
      uri`https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?proximity=ip&access_token=${
        import.meta.env.VITE_MAPBOX_ACCESS_TOKEN as string
      }`,
    );
  },
);

const pipe = textChange$.pipe(
  debounceTime(250),
  findAddresses,
  map(({ data }) => data?.features ?? []),
);

const [useAddresses] = bind(pipe, []);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function FormikLocationField<
  ID extends string,
  T extends { [key in ID]?: GeoJSONPoint | null },
>(props: Props<ID, T>): JSX.Element {
  const { formik, fullWidth, id, label, margin, onInvalid, size, variant } =
    props;
  const [value, setValue] = useState<string | AddressFeature>(
    formatPoint(formik.values[props.id]),
  );
  const [error, setError] = useState<string>();
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    setValue(formatPoint(formik.values[props.id]));
  }, [formik.values[id]]);

  const addresses = useAddresses();

  const onChange = (
    _: SyntheticEvent<Element, Event>,
    value: string | AddressFeature | null,
  ): void => {
    if (!value) {
      formik.setFieldValue(id, undefined).catch(captureException);
      setError(undefined);
      return;
    }

    if (typeof value === 'string') {
      computePoint(value);
      return;
    }

    const geometry = value.geometry;
    formik.setFieldTouched(id, true);
    formik.setFieldValue(id, geometry, true).catch(captureException);
    setValue(formatPoint(geometry as GeoJSONPoint));
    setText('');
    setError(undefined);
  };

  const computePoint = (value: string): void => {
    setTouched(true);

    const parts = value
      .split(',')
      .map((part) => part.trim())
      .map((part) => Number(part))
      .filter(
        (part) => !Number.isNaN(part) && part !== null && part !== undefined,
      );

    if (parts.length !== 2) {
      setError('Expected: <lat>, <long>');

      return;
    }
    if (parts[0] > 90 || parts[0] < -90) {
      setError('Latitude must be between -90 and 90');
      return;
    }
    if (parts[1] > 180 || parts[1] < -180) {
      setError('Longitude must be between -180 and 180');
      return;
    }
    const point = {
      type: 'Point',
      coordinates: [parts[1], parts[0]],
    } satisfies GeoJSONPoint;
    formik.setFieldTouched(id, true);
    formik.setFieldValue(id, point, true).catch(captureException);
    setValue(formatPoint(point));
    setText('');
    setError(undefined);
  };

  const handleOnBlur: FocusEventHandler<HTMLDivElement> = (evt) => {
    setValue(formatPoint(formik.values[id]));
  };

  return (
    <Autocomplete
      freeSolo
      autoSelect
      autoComplete
      clearOnEscape
      filterSelectedOptions
      fullWidth={fullWidth ?? true}
      options={addresses}
      onBlur={handleOnBlur}
      onChange={onChange}
      value={value}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option;
        return option.place_name;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id={id}
          disabled={formik.isSubmitting || props.disabled}
          label={label}
          error={
            touched &&
            (!!error || Boolean(props.id in formik.errors) || props.error)
          }
          helperText={error ?? getError(formik.errors[id]) ?? props.helperText}
          margin={margin}
          onChange={({ target }) => setText(target.value)}
          onInvalid={onInvalid}
          size={size}
          variant={variant}
        />
      )}
    />
  );
}

const getError = (
  errors:
    | string
    | string[]
    | FormikErrors<unknown>
    | FormikErrors<unknown>[]
    | undefined,
): string | undefined => (errors ? String(errors) : undefined);
