import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { captureException } from '@sentry/browser';
import type { GeoJSONPoint } from 'core';
import type { FormikProps } from 'formik';
import type { OverlayImage } from '../../utils/types.js';
import { CenterMiniMap } from '../map/center-mini-map/controller.js';
import { DraggableMarkerLayer } from '../map/layers/draggable-marker.js';
import { OverlayImageLayer } from '../map/layers/overlay-image.js';
import { MapStyles } from '../map/map-styles.js';

type Props<ID, T> = BoxProps & {
  id: ID;
  formik: FormikProps<T>;
  height?: number | string;
  overlayImage?: OverlayImage | null;
};

export function FormikLocationMapField<
  ID extends string,
  T extends { [key in ID]?: GeoJSONPoint | null },
>(props: Props<ID, T>): JSX.Element {
  const { formik, id, overlayImage, ...boxProps } = props;

  // TODO no location

  return (
    <Box
      {...boxProps}
      sx={(theme) => ({
        borderRadius: `${theme.shape.borderRadius}px`,
        overflow: 'clip',
      })}
    >
      <CenterMiniMap
        width="100%"
        height={boxProps.height ?? 120}
        mapStyle={MapStyles.SATELLITE}
        center={formik.values[id]}
      >
        {overlayImage ? (
          <OverlayImageLayer
            corners={overlayImage.corners}
            imageUrl={overlayImage.url}
            interpolateOpacity={true}
          />
        ) : null}
        <DraggableMarkerLayer
          point={formik.values[id]}
          onPointChange={(point) => {
            formik.setFieldValue(id, point).catch(captureException);
          }}
        />
      </CenterMiniMap>
    </Box>
  );
}
